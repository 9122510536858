import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import urlBuilder from "@sanity/image-url"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import * as ResearchStyles from '../components/research.module.css'

export const query = graphql`
query ($slug: String!)
{
    sanityResearch(slug:{current: {eq:$slug}}){
    title
    description
    slug {
      current
    }
    tag {
      tagName
    }
    headerImg {
      asset {
        gatsbyImageData(width: 1480)
        originalFilename
      }
    }
    img {
      asset {
        gatsbyImageData(width: 1480) 
        originalFilename
        metadata {
          dimensions {
            aspectRatio
          }
        }
      }
    }
    _rawText
  }
}
`;

const urlFor = source =>
  urlBuilder({ projectId: "frgqt3qn", dataset: "production" }).image(source);

  const serializer = {
    types: {
      image: props => (
        <figure>
          <img
            src={urlFor(props.node.asset)
              //.width(600)
              .url()}
            alt={props.node.alt}
          />
        </figure>
      )
    }
  };  
  
const Research = ({ data }) => {
//    const tags = data.sanityResearch.tag.tagName;
//    console.log(tags)
    return(
        <Layout>              
            <div className={ResearchStyles.image}>
                <GatsbyImage image = { data.sanityResearch.headerImg.asset.gatsbyImageData } alt={ data.sanityResearch.headerImg.asset.originalFilename} />
            </div>
            
            <div className={ResearchStyles.printPres}>
                <div className={ResearchStyles.description}>
                <div className={ResearchStyles.lineBreak}></div>
                <h2> { data.sanityResearch.title }</h2>
                <div className={ResearchStyles.lineBreak}></div>
     
                  <p> { data.sanityResearch.description } </p>
                <div className={ResearchStyles.lineBreak}></div>
              </div>
            </div>
  
            <div className={ResearchStyles.textPres}>  
                <BlockContent blocks = { data.sanityResearch._rawText } serializers = { serializer } />
            </div>
              

              <div className={ResearchStyles.researchImages}>
                {
                  data.sanityResearch.img.map(( image, index) => (
                    
                      <GatsbyImage 
                      className={ResearchStyles.galleryImages}
                      image = { image.asset.gatsbyImageData } alt={ image.asset.gatsbyImageData.originalFilename} />
                      
                  ))
                }
              </div>

            
        </Layout>
      
    )
}

export default Research