// extracted by mini-css-extract-plugin
export var imagePanels = "research-module--image-panels--1T2HK";
export var imagePanelImg = "research-module--image-panel-img--7iRMw";
export var imagePanelItem = "research-module--image-panel-item--2syYJ";
export var subtitle = "research-module--subtitle--296kD";
export var tag = "research-module--tag--3i1VF";
export var printPres = "research-module--print-pres--R_s4H";
export var description = "research-module--description---SQgq";
export var details = "research-module--details--2AXmX";
export var lineBreak = "research-module--line-break--1qpoL";
export var textPres = "research-module--text-pres--1gThS";
export var mobile = "research-module--mobile--3_k9c";
export var researchImages = "research-module--research-images--3c0LW";
export var desktop = "research-module--desktop--3JOmQ";
export var galleryImages = "research-module--galleryImages--3PmGO";